<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-form label-width="120px" label-position="right">
        <el-form-item label="商家编号:">{{
          formInline.storeCode
        }}</el-form-item>
        <el-form-item label="商家名称:">{{
          formInline.storeName
        }}</el-form-item>
        <el-form-item label="商家描述:">{{
          formInline.description
        }}</el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="formInline.storeState"
            active-color="#409EFF"
            inactive-color="#909399"
            active-value="1"
            inactive-value="2"
            disabled
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="账户余额：">
          <span>{{ balance || "0.00" }}元</span>
          <el-button type="text" style="margin: 0 40px" @click="seeDetail"
            >查看明细</el-button
          >
          <el-button type="text" style="color: #f56c6c" @click="refund"
            >退款</el-button
          >
        </el-form-item>
        <div class="title">合作车场</div>
        <el-form-item label="商户名称：">{{
          formInline.operationName
        }}</el-form-item>
        <el-form-item label="合作车场：">{{ storeParks }}</el-form-item>
      </el-form>
    </div>
    <refund
      ref="refund"
      :balance="balance"
      :storeId="formInline.storeId"
      :storeName="formInline.storeName"
    ></refund>
  </div>
</template>

<script>
import refund from "./refund";
export default {
  filters: {
    timeFilter(val) {
      if (!val) return;
      return val.split(" ")[0];
    },
  },
  data() {
    return {
      url: "",
      srcList: [],
      dialogVisible: false,
      slotTitle: "",
      slotType: "",
      formInline: {},
      balance: "0.00",
      storeParks: "", // 合作车场
    };
  },
  components: {
    refund,
  },
  mounted() {
    console.log(this.$route.query, "this.$route.query");
    this.formInline = this.$route.query;
    if (this.$route.query.operationId) {
      this.getBalance();
    }

    this.storeParks = this.$route.query.parkNames;
    // this.$route.query.storeParks.forEach((item) => {
    //   this.storeParks.push(item.parkName);
    // });
    // this.storeParks = this.storeParks.join(",");

    console.log(this.formInline, "this.formInline");
  },
  methods: {
    // 退款
    refund() {
      this.$refs.refund.open();
    },
    // 查看明细
    seeDetail() {
      this.$router.push({
        path: "/balanceDetails",
        query: {
          storeId: this.$route.query.storeId,
        },
      });
    },
    getBalance() {
      const opt = {
        url: `/acb/2.0/storeBalanceRecord/getBalance?storeId=${this.formInline.storeId}`,
        method: "get",
        success: (res) => {
          if (res.state == 0) {
            this.balance = res.value || "0.00";
          }
        },
        fail: (err) => {},
      };
      this.$request(opt);
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
  text-align: right;
  margin: 20px 0;
}

.content {
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.title {
  font-size: 14px;
  color: #1f2d3d;
  font-weight: 900;
  margin: 36px 18px;
}
</style>
